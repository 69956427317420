<template>
  <div style="height: inherit">
    <div class="row">
      <div class="col-3 col-md-3">
        <div>
          <b-card>
            <div class="product-categories">
              <b-form-radio v-model="searchCategory" :value="null" class="mb-1"
                >Todos</b-form-radio
              >
              <b-form-radio-group
                v-model="searchCategory"
                class="categories-radio-group"
                stacked
                :options="categoriesOptions"
                text-field="name"
                value-field="id"
              />
            </div>
          </b-card>
          <b-card no-body>
            <b-card-header class="flex-column align-items-start">
              <b-card-title>Métodos de Pago</b-card-title>
            </b-card-header>
            <b-card-body>
              <!-- Radios -->
              <b-form-group>
                <b-form-checkbox
                  v-for="option in payments"
                  :key="option.code"
                  v-model="selected"
                  :value="option.code"
                  @input.native="paymentMethod = option.code"
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/6/6f/Wallet_Flat_Icon.svg"
                    alt=""
                  />
                  {{ option.name }}
                </b-form-checkbox>
              </b-form-group>
            </b-card-body>
          </b-card>

          <div class="amount-payable">
            <!-- checkout-options -->

            <b-card title="Listado de Compra">
              <ul class="list-unstyled price-details">
                <vuescroll
                  v-if="cartProducts.length"
                  :ops="perfectScrollbarSettings"
                  class="scrollable-container media-list scroll-area"
                  style="height: calc(20vh - 20px)"
                  tagname="li"
                >
                  <li
                    v-for="product in $store.state['app-ecommerce'].cartItems
                      .slice()
                      .reverse()"
                    :key="product.id"
                    class="price-detail"
                  >
                    <br />

                    <div>({{ product.qty }}) {{ product.name }}</div>

                    <div v-if="product.discount_type == 'percent'">
                      Precio neto:
                      {{
                        (product.price * product.qty)
                          | currency({ symbol: "$", precision: 2 })
                      }}
                    </div>

                    <div v-if="product.discount_type == 'percent'">
                      Descuento: {{ product.discount
                      }}{{ product.discount_type == "percent" ? "%" : "$" }}
                    </div>

                    <div v-if="product.discount_type == 'percent'">
                      Total con Descuento:
                      <strong>{{
                        showPriceTotal(product)
                          | currency({ symbol: "$", precision: 2 })
                      }}</strong>
                    </div>
                  </li>
                </vuescroll>
              </ul>

              <hr />

              <div>
                Total a Pagar:
                <strong>
                  {{ totalAmount | currency({ symbol: "$", precision: 2 }) }}
                </strong>
                -
                <small>
                  {{
                    ($store.state.bank.data.amount * totalAmount)
                      | currency({ symbol: "VEF", precision: 2 })
                  }}
                </small>
              </div>
              <div class="checkout-options">
                <b-card>
                  <div class="price-details">
                    <b-overlay
                      :show="loading"
                      rounded
                      opacity="0.6"
                      spinner-small
                      spinner-variant="primary"
                    >
                      <b-button
                        v-if="paymentMethod == 'PUNTO_VENTA'"
                        @click="preparePayPOS()"
                        variant="primary"
                        :disabled="invalid"
                        block
                      >
                        Pagar
                      </b-button>
                    </b-overlay>
                  </div>
                </b-card>
              </div>
            </b-card>
            <b-modal
              v-model="showModal"
              hide-footer
              centered
              title=""
              no-close-on-backdrop
              no-header-close
            >
              <b-overlay
                :show="loadingModal"
                rounded
                variant="transparent"
                opacity="0.33"
                blur="2px"
              >
                <div>
                  <img
                    class="img-fluid"
                    src="https://s3.amazonaws.com/assets.andinoapp.com/pos/image_processing20200928-13424-1nsj23g.gif"
                    alt=""
                  />
                </div>

                <div class="demo-spacing-0" v-if="loadingPOS">
                  <b-alert variant="info" show>
                    <h4 class="alert-heading">
                      Preparando punto de venta, por favor espere....
                    </h4>
                  </b-alert>
                </div>

                <div class="demo-spacing-0" v-if="loadingCard">
                  <b-alert variant="secondary" show>
                    <h4 class="alert-heading">
                      Por favor inserte su tarjeta en el punto de venta....
                    </h4>
                  </b-alert>
                </div>
              </b-overlay>
            </b-modal>
            <b-modal
              v-model="showModalQr"
              hide-footer
              centered
              title=""
              no-close-on-backdrop
              no-header-close
              @hidden="handleModalHidden"
            >
              <b-overlay
                :show="loadingModalModalQr"
                rounded
                variant="transparent"
                opacity="0.33"
                blur="2px"
              >
                <div>
                  <qrcode-stream
                    v-if="paymentMethod == 'WALLET'"
                    @decode="onDecode"
                  ></qrcode-stream>
                </div>
              </b-overlay>
            </b-modal>
            <b-modal
              v-model="showModalUser"
              hide-footer
              centered
              title="Selecciona Colegio"
              no-close-on-backdrop
              no-header-close
              hide-header-close
              @hidden="handleModalHidden"
            >
              <b-overlay
                :show="loadingModalModalUser"
                rounded
                variant="transparent"
                opacity="0.33"
                class="text-center"
                blur="2px"
              >
                <div class="d-flex flex-wrap justify-content-center">
                  <div v-for="item in schoolsData" :key="item.id" class="p-2">
                    <b-card
                      tag="label"
                      style="max-width: 20rem"
                      class="mb-2 cursor-pointer"
                    >
                      <img
                        :src="item.school.logo"
                        alt="Card image"
                        class="card-img-top"
                      />
                      <b-card-body>
                        <p>{{ item.school.name }}</p>
                        <b-card-text>
                          <b-form-radio
                            @change="getUser()"
                            v-model="userId"
                            :value="item.id"
                            class="mb-1"
                            unchecked-value="null"
                          >
                            Seleccionar
                          </b-form-radio>
                        </b-card-text>
                      </b-card-body>
                    </b-card>
                  </div>
                </div>
              </b-overlay>
            </b-modal>
          </div>
        </div>
      </div>
      <div class="col-6 col-md-6">
        <b-form @submit.prevent="getProducts()">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="search"
              placeholder="Buscar Productos"
              class="search-product"
            />
            <b-input-group-append is-text @click="getProducts()">
              <feather-icon
                icon="SearchIcon"
                size="25"
                class="text-muted cursor-pointer"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form>
        <br />
        <b-pagination
          v-model="items.current_page"
          :total-rows="items.total"
          :per-page="items.per_page"
          first-number
          @change="getProducts"
          align="right"
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>

        <vuescroll
          v-if="items.data"
          :ops="perfectScrollbarSettings"
          class="scrollable-container media-list scroll-area"
          style="height: calc(80vh - 80px)"
          tagname="li"
        >
          <b-overlay :show="loadingSearchProduct" rounded="sm">
            <section id="boxProduct" :class="itemView">
              <b-card
                v-for="product in items.data"
                :key="product.id"
                class="ecommerce-card flex-row"
                no-body
              >
                <!-- Image Part -->
                <div class="item-img flex-shrink-0">
                  <b-link>
                    <b-img
                      :alt="`${product.name}-${product.id}`"
                      fluid
                      class="card-img-left"
                      :src="product.image"
                    />
                  </b-link>
                </div>

                <!-- Info Part -->
                <b-card-body
                  class="d-flex flex-column justify-content-between ml-2"
                >
                  <div>
                    <div class="item-name">
                      <b-link class="text-body">
                        {{ product.name }}
                      </b-link>
                    </div>
                    <div>
                      <h6 class="item-price text-nowrap">
                        {{
                          product.price
                            | currency({ symbol: "$", precision: 2 })
                        }}
                      </h6>
                    </div>
                    <div v-if="product.stock">
                      <span>
                        Disponibles <strong>{{ product.stock }}</strong>
                      </span>
                    </div>
                  </div>

                  <!-- Product Actions -->
                  <div class="item-options mt-auto">
                    <b-button
                      variant="primary"
                      tag="a"
                      class="btn-cart"
                      @click="handleCartActionClick(product)"
                    >
                      <feather-icon icon="ShoppingCartIcon" class="mr-50" />
                      <span>Agregar al Carrito</span>
                    </b-button>
                  </div>
                </b-card-body>
              </b-card>
            </section>
          </b-overlay>
        </vuescroll>
      </div>
      <div class="col-3 col-md-3">
        <b-card>
          <div class="checkout-items">
            <vuescroll
              v-if="cartProducts.length"
              :ops="perfectScrollbarSettings"
              class="scrollable-container media-list scroll-area"
              style="height: calc(80vh - 80px)"
              tagname="li"
            >
              <b-card
                v-for="(product, indexTr) in cartProducts"
                :key="indexTr"
                class="ecommerce-card d-flex align-items-center"
                no-body
              >
                <!-- Product Image -->
                <div class="item-img mr-2 flex-shrink-0">
                  <b-link>
                    <b-img
                      :src="product.image"
                      :alt="`${product.name}-${product.id}`"
                      class="img-fluid"
                    />
                  </b-link>
                </div>

                <!-- Product Details: Card Body -->
                <b-card-body class="flex-grow-1">
                  <div class="item-name">
                    <h6 class="mb-0">
                      <b-link class="text-body">
                        {{ product.name }}
                      </b-link>
                    </h6>
                    <span v-if="product.category" class="item-company">
                      {{ product.category.name }}
                    </span>
                    <span v-if="product.stock" class="text-success mb-1">
                      Disponible {{ product.stock - product.qty }}
                    </span>
                    <div class="item-quantity">
                      <span class="quantity-title">Cantidad:</span>
                      <b-form-spinbutton
                        :value="product.qty"
                        size="sm"
                        class="ml-75"
                        inline
                        @input="updateQtyInProduct($event, product)"
                        :max="product.stock"
                      />
                    </div>
                    <span
                      class="delivery-date text-muted"
                      v-if="product.discount_type == 'percent'"
                    >
                      Descuento
                      <span class="text-success">{{ product.discount }}%</span>
                    </span>
                    <!-- Product Options/Actions -->
                    <div class="item-options mt-2">
                      <div class="item-wrapper">
                        <div class="item-cost">
                          <h4 class="item-price">
                            {{
                              product.price
                                | currency({ symbol: "$", precision: 2 })
                            }}
                          </h4>
                          <small>
                            {{
                              ($store.state.bank.data.amount * product.price)
                                | currency({ symbol: "VEF", precision: 2 })
                            }}
                          </small>
                        </div>
                      </div>
                      <b-button
                        variant="light"
                        class="mt-1 remove-wishlist"
                        @click="removeProductFromCart(product)"
                      >
                        <feather-icon icon="XIcon" class="mr-50" />
                        <span>Borrar</span>
                      </b-button>
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </vuescroll>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  BImg,
  BCardText,
  BButton,
  BPagination,
  VBTooltip,
  BForm,
  BOverlay,
  BBadge,
  BFormSpinbutton,
  BCardHeader,
  BCardTitle,
  BFormGroup,
  BFormCheckbox,
  BModal,
  VBModal,
  BAlert,
} from "bootstrap-vue";
import vuescroll from "vuescroll";

import Ripple from "vue-ripple-directive";
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";
import { useShopUi } from "./useECommerceShop";
import Push from "push.js";

import vSelect from "vue-select";
import { required } from "@validations";
import { localize } from "vee-validate";
import es from "vee-validate/dist/locale/es.json";
localize("es", es);

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from "vue-qrcode-reader";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  components: {
    // BSV
    BOverlay,
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
    BForm,
    vuescroll,
    BBadge,
    BFormSpinbutton,
    vSelect,
    vuescroll,
    BCardHeader,
    BCardTitle,
    BFormGroup,
    BFormRadio,
    BFormCheckbox,
    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture,
    BModal,
    VBModal,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BAlert,
  },

  created() {
    // this.showModalUser = true;
    this.getProducts();
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
  },
  destroyed() {
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", this.menuHidden);
  },
  data() {
    return {
      loadingModalModalUser: false,
      items: {},
      menuHidden: this.$store.state.appConfig.layout.menu.hidden,
      search: null,
      searchUser: null,
      searchCategory: null,
      categoriesOptions: [],
      loadingSearchProduct: false,
      loadingSearchCategory: false,
      userId: null,
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          background: "#01a99a",
        },
        bar: {
          background: "#ee3926",
        },
      },
      timeOutSearch: null,
      selected: null,
      allProducts: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
        rail: {
          background: "#01a99a",
        },
        bar: {
          background: "#ee3926",
        },
      },
      schoolsData: [],
      required,
      refId: null,
      showModal: false,
      invalid: false,
      payments: [],
      selected: null,
      paymentMethod: null,
      walletId: null,
      order: {},
      loading: false,
      loadingModal: false,
      loadingPOS: false,
      loadingCard: false,
      showModalQr: false,
      loadingModalModalQr: false,
      showModalUser: false,
    };
  },
  computed: {
    totalAmount() {
      let total = 0;
      let items = this.$store.state["app-ecommerce"].cartItems;
      items.forEach((i) => {
        total += this.showPriceTotal(i);
      });
      return total;
    },
    notification() {
      return this.$store.getters["user/userData"].notifications;
    },
    cartLength() {
      return this.$store.state["app-ecommerce"].cartItems.length;
    },
    cartProducts() {
      return this.$store.state["app-ecommerce"].cartItems.slice().reverse();
    },
  },
  methods: {
    handleModalHidden() {
      this.paymentMethod = null;
      this.selected = null;
    },
    async preparePayPOS() {
      //  /*  this.loadingModal = true;
      //   this.showModal = true;
      //   this.loadingPOS = true;
      //   /* await this.$store
      //     .dispatch("pos/preparePOS")
      //     .then(async () => {
      //       this.loadingModal = false;
      //       this.loadingPOS = false;
      //       this.loadingCard = true;
      //     })
      //     .catch((error) => {
      //       console.log("error->preparePayPOS", error);
      //     }); */
      //   await this.$store
      //     .dispatch("products/pay", {
      //       data: {
      //         userId: this.userId,
      //         totalOrder: this.totalAmount,
      //         paymentId: this.paymentMethod,
      //         order: this.$store.state["app-ecommerce"].cartItems.map((item) => {
      //           if (item.discount_type == "percent") {
      //             (item.price - item.price * (item.discount / 100)) * item.qty;
      //           } else {
      //             (item.price - item.discount) * item.qty;
      //           }
      //           return {
      //             productId: item.id,
      //             qty: item.qty,
      //             productPrice: item.price,
      //             price: item.price * item.qty,
      //             discount: item.discount,
      //             total: this.showPriceTotal(item),
      //           };
      //         }),
      //       },
      //     })
      //     .then(async () => {
      //       this.showModal = false;
      //       this.loadingModal = false;
      //       this.loadingPOS = false;
      //       this.loadingCard = false;
      //       this.selected = null;
      //       this.$swal({
      //         title: "Exito",
      //         text: "Pago realizado ya puede retirar su tarjeta",
      //         icon: "success",
      //         customClass: {
      //           confirmButton: "btn btn-primary",
      //         },
      //         buttonsStyling: false,
      //       }).then((result) => {
      //         if (result.isConfirmed) {
      //           this.printTicket();
      //         }
      //       });
      //       this.$store.commit("app-ecommerce/EMPTY_CART");
      //       this.paymentMethod = null;
      //     })
      //     .catch((error) => {
      //       this.loadingModal = false;
      //       this.loadingPOS = false;
      //       this.loadingCard = false;
      //       this.selected = null;
      //       this.paymentMethod = null;
      //       console.log("asdasd", error);
      //     }); */
    },
    printTicket() {
      this.$swal({
        title: "Confirmación",
        text: 'Imprima su ticket para retirar su pedido',
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          alert("Imprimiendo ticket");
        }
      });
    },
    close() {
      this.showModal = false;
      this.selected = null;
      this.paymentMethod = null;
    },
    onDecode(key) {
      this.walletId = key;
      this.pay();
    },
    showPriceTotal({ discount_type, price, discount, qty }) {
      if (discount_type == "percent") {
        return (price - price * (discount / 100)) * qty;
      } else {
        if (discount_type == null || discount == null) {
          return price * qty;
        }

        return (price - discount) * qty;
      }
    },
    async getPayments() {
      this.payments = await this.$store.dispatch("pos/getPayments", {
        payment: "ATM",
      });
    },
    validatePay() {
      if (!this.paymentMethod) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "AlertCircleIcon",
            variant: "danger",
            text: "Debes de seleccionar un método de pago",
          },
        });
        return;
      } else {
        this.pay();
      }
    },
    async pay() {
      await this.$store
        .dispatch("products/pay", {
          walletId: this.walletId,
          totalOrder: this.totalAmount,
          from: 'ATM',
          payments: [
            {
              paymentMethod: this.paymentMethod,
              amount: this.totalAmount,
            },
          ],
          order: this.$store.state["app-ecommerce"].cartItems.map((item) => {
            if (item.discount_type == "percent") {
              (item.price - item.price * (item.discount / 100)) * item.qty;
            } else {
              (item.price - item.discount) * item.qty;
            }

            return {
              productId: item.id,
              qty: item.qty,
              productPrice: item.price,
              price: item.price * item.qty,
              discount: item.discount,
              total: this.showPriceTotal(item),
            };
          }),
        })
        .then(async (res) => {
          this.showModalQr = false;
          this.selected = null;
          this.$swal({
            title: "Exito",
            text: res.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.isConfirmed) {
              this.printTicket();
            }
          });
          this.$store.commit("app-ecommerce/EMPTY_CART");
        })
        .catch((error) => {
          this.showModalQr = false;
          this.selected = null;
          this.paymentMethod = null;
          this.$swal({
            title: "Error",
            text: error.response.data.message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          /* .then((result) => {
            
          });
          console.log('asdasd>>>>>>>' , error.message) */
        });
    },
    changeProductQuantity(product) {
      this.$store.commit("app-ecommerce/UPDATE_PRODUCT_CART", product);
      this.selected = null;
    },
    updateQtyInProduct($event, product) {
      product.qty = $event;
      this.$store.commit("app-ecommerce/UPDATE_PRODUCT_CART", product);
    },
    removeProductFromCart(product) {
      this.$store.commit("app-ecommerce/REMOVE_PRODUCT_FROM_CART", product.id);
    },
    async getUser() {
      this.loadingModalModalUser = true;
      try {
        let res = await this.$store.dispatch("pos/getUser", {
          search: this.userId,
        });
        this.userId = res.user_id;
        this.showModalUser = false;
        this.loadingModalModalUser = false;
        this.getProducts();
      } catch (error) {
        this.loadingModalModalUser = false;
        console.log(error);
      }
    },
    getDay(day) {
      switch (day) {
        case 1:
          return "Lunes";
        case 2:
          return "Martes";
        case 3:
          return "Miercoles";
        case 4:
          return "Jueves";
        case 5:
          return "Viernes";
      }
    },
    async userNotifications() {
      if (this.notification.length > 0) {
        this.notification.forEach((item) => {
          const res = {
            ...item,
            onClose: async () => {
              await this.$store
                .dispatch("notifications/close", {
                  notificationId: item.id,
                  params: { private: 1 },
                })
                .then(async () => {
                  await this.$store.dispatch("user/getUserData");
                });
            },
          };
          Push.create("Andino App", res);
        });
      }
    },
    async getSchools() {
      try {
        this.loadingSearchSchool = true;
        this.schoolsData = await this.$store.dispatch("pos/indexSchool", {
          paginate: false,
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingSearchSchool = false;
      }
    },
    async getCategories() {
      try {
        this.loadingSearchCategory = true;
        this.categoriesOptions = await this.$store.dispatch(
          "pos/indexCategory",
          { paginate: false }
        );
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingSearchCategory = false;
      }
    },
    async getProducts(page = 1) {
      try {
        this.loadingSearchProduct = true;
        this.items = await this.$store.dispatch("pos/index", {
          page: page,
          search: this.search,
          searchCategory: this.searchCategory,
          shopSecond: 32,
          userId: this.userId,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingSearchProduct = false;
      }
    },
    handleCartActionClick(product) {
      this.$store.commit("app-ecommerce/UPDATE_PRODUCT_CART", product);
    },
    selectCategory(id) {
      if (window.innerWidth < 992) {
        window.scrollTo(0, 177);
      }
      this.searchCategory = id;
    },
  },
  mounted() {
    this.getPayments();
    this.getCategories();
    this.getSchools();
  },
  watch: {
    selected(payment) {
      if (!payment) {
        this.paymentMethod = null;
      } else if (this.paymentMethod == "WALLET") {
        this.showModalQr = true;
      }
    },
    searchCategory() {
      this.getProducts();
    },
    search() {
      clearTimeout(this.timeOutSearch);

      this.timeOutSearch = setTimeout(() => {
        this.getProducts();
      }, 800);
    },
    searchUser() {
      clearTimeout(this.timeOutSearch);

      this.timeOutSearch = setTimeout(() => {
        this.getUser();
      }, 800);
    },
  },
  setup() {
    const { itemView, itemViewOptions } = "list-view";
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();

    return {
      itemViewOptions,
      itemView,
      mqShallShowLeftSidebar,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
  ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}
.sticky {
  position: fixed;
  top: 72px;
  left: 0;
  right: 0;
  z-index: 1;
  margin: 15px;
}
#boxCategory {
  overflow: hidden;
}
#marginProduct {
  margin-top: 1000px !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
[dir] .b-form-spinbutton.form-control .btn-sm,
[dir] .b-form-spinbutton.form-control .btn-group-sm > .btn {
  padding: 0 !important;
}
.ecommerce-card {
  align-items: stretch;
}

.item-img {
  width: 200px;
}
</style>
